<template>
  <div class="register-form">
    <v-form v-model="form_valid" ref="register_form" class="bordered pa-8">
      <div>
        <h1>New Shop Application</h1>
        <p class="pb-4">
          Fill in the form below to register a new shop to your existing brand.
        </p>
      </div>
      <v-alert
        :value="success"
        type="success"
        colored-border
        border="left"
        color="primary"
        dismissible
        @input="(v) => (success = v)"
      >
        <p>Merchant successfully created.</p>
        <v-btn
          v-if="previous_shop_id"
          :to="{ name: 'admin-shops-id', params: { id: previous_shop_id } }"
          :disabled="!are_assets_ready"
          :loading="!are_assets_ready"
          rounded
          color="primary"
        >
          <p v-if="!are_assets_ready" class="mb-0 mr-2">Generating assets</p>
          <p v-else class="mb-0 mr-2">Manage Shop</p>
        </v-btn>
      </v-alert>
      <v-checkbox
        color="primary"
        v-model="directory_shop"
        label="Make shop directory only. (The shop won't be able to accept orders if this is ticked)"
      />
      <v-text-field
        color="primary"
        prepend-icon="mdi-at"
        label="Email Address *"
        hint="This email will be used as login information"
        v-model="email"
        :rules="rules_email"
        required
      />
      <v-text-field
        prepend-icon="mdi-form-textbox"
        label="Business Name *"
        hint="Trading name. Cannot be changed later"
        v-model="business_name"
        :rules="rules_txt_req"
        color="primary"
        maxlength="50"
        counter="50"
        required
      />
      <v-autocomplete
        v-if="brands"
        :items="brands.map((b) => ({ ...b, header: null }))"
        :rules="[field_req]"
        :disabled="is_community_admin"
        v-model="brand"
        item-text="name"
        item-value="id"
        color="primary"
        prepend-icon="mdi-domain"
        label="Choose Your Brand *"
        @change="(selected_brand) => (brand = selected_brand)"
      ></v-autocomplete>
      <v-select
        v-model="payment_processor"
        :items="[
          {
            text: 'Stripe',
            value: 'stripe',
          },
          {
            text: 'Unify',
            value: 'unify',
          },
        ]"
        :rules="[field_req]"
        :disabled="!!$route.query.add_a_business_id"
        color="primary"
        prepend-icon="mdi-currency-gbp"
        label="Payment Processor*"
      ></v-select>
      <v-text-field
        color="primary"
        label="Business Owner's Full Name *"
        prepend-icon="mdi-account-tie"
        v-model="user_name"
        :rules="rules_txt_req"
        maxlength="50"
        counter="50"
        required
      />
      <template v-if="!directory_shop">
        <v-text-field
          color="primary"
          label="Company Name *"
          prepend-icon="mdi-form-textbox"
          v-model="company_details.company_name"
          :rules="rules_txt_req"
          required
        />
        <v-text-field
          color="primary"
          class="remove-spinner"
          label="Company Number *"
          prepend-icon="mdi-numeric"
          v-model="company_details.company_number"
          :rules="rules_txt_req"
          type="number"
          @keydown="handle_numeric_input($event)"
          required
        />
        <v-text-field
          color="primary"
          label="Address 1 *"
          prepend-icon="mdi-map-marker"
          v-model="company_details.address_1"
          :rules="rules_txt_req"
          required
        />
        <v-text-field
          color="primary"
          label="Address 2"
          prepend-icon="mdi-map-marker"
          v-model="company_details.address_2"
          required
        />
        <v-text-field
          color="primary"
          label="Address 3"
          prepend-icon="mdi-map-marker"
          v-model="company_details.address_3"
          required
        />
        <div class="d-flex">
          <v-text-field
            color="primary"
            label="Town *"
            prepend-icon="mdi-town-hall"
            v-model="company_details.town"
            :rules="rules_txt_req"
            required
          />
          <v-text-field
            color="primary"
            label="Postcode *"
            prepend-icon="mdi-email"
            v-model="company_details.postcode"
            :rules="rules_txt_req"
            required
          />
        </div>
      </template>
      <v-col cols="12" class="text-right">
        <v-btn
          color="primary"
          rounded
          class="mr-0"
          @click="submit"
          :disabled="!form_valid || loading"
        >
          <v-progress-circular
            v-if="loading"
            :size="20"
            indeterminate
            color="white"
          />
          <span v-else>Register</span>
        </v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { db } from "@/db";
import { format_string_to_uid } from "@/utils/firestore";

import { handle_numeric_input } from "@/utils/utils";
import { CustomError } from "@/utils/errors";
import {
  field_req,
  txt_min_2_char,
  valid_password,
} from "@/utils/form_val_rules";
import { AddABusinessStatus } from "@/const";

import {
  create_shop,
  get_add_a_business_request,
  create_request_resolution_task,
} from "@/requests";

const clean_company_details = {
  company_name: null,
  company_number: null,
  address_1: null,
  address_2: null,
  address_3: null,
  town: null,
  postcode: null,
};

export default {
  name: "Register",
  data() {
    return {
      handle_numeric_input,
      success: false,
      loading: false,
      are_assets_ready: false,
      form_valid: false,
      business_name: null,
      previous_shop_id: "",

      user_name: null,
      email: null,
      brand: null,
      password: null,
      payment_processor: null,
      company_details: { ...clean_company_details },
      confirm_password: null,
      directory_shop: false,
      show_password: true,
      rules_txt_req: [field_req, txt_min_2_char],
      rules_email: [
        field_req,
        (v) =>
          (/\S+@\S+\.\S+/.test(v) &&
            !/^\S+@digitalconstruction.technology$/.test(v)) ||
          "Email must be valid",
      ],
      rules_pwd: [field_req, valid_password],
      rules_confirm_pwd: [
        field_req,
        (v) =>
          v === this.password || "Passwords do not match, please try again",
      ],
      field_req,
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["brands"]),
    ...mapState("UserStore", ["is_community_admin"]),
    ...mapState("UserStore", { community_admin_brand_id: "brand_id" }),
  },
  async mounted() {
    this.setup_community_manager_brand();

    const add_a_business_id = this.$route.query.add_a_business_id;
    if (add_a_business_id) {
      try {
        const { data: aab } = await get_add_a_business_request(
          add_a_business_id
        );

        if (aab.status !== AddABusinessStatus.PENDING) {
          this.$router.push({
            name: "admin-brands-id-requests",
            params: { brand_id: aab.brand_id },
          });
          this.set_error({
            error: {
              message: `This request has already been actioned. Redirecting to Requests page.`,
            },
          });
        }

        this.payment_processor = "stripe";
        this.business_name = aab.name;
        this.user_name = `${aab.first_name} ${aab.last_name}`;
        this.directory_shop = aab.is_directory_only;
        this.brand = aab.brand_id;
        this.email = aab.email;

        for (var key in this.company_details) {
          this.company_details[key] = aab[key];
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_shops"]),
    ...mapActions("ErrorStore", ["set_error"]),

    async is_existing_shop(shop_id) {
      const shop = await db.collection("shops").doc(shop_id).get();
      return shop.exists;
    },
    setup_community_manager_brand() {
      if (this.is_community_admin) this.brand = this.brands[0].id;
    },
    async submit() {
      this.loading = true;
      this.are_assets_ready = false;
      try {
        const shop_id = format_string_to_uid(this.business_name);
        this.previous_shop_id = shop_id;
        const is_existing_shop = await this.is_existing_shop(shop_id);

        if (is_existing_shop) {
          throw new CustomError(
            "Sorry, this business name is already occupied. Please choose different one."
          );
        }

        const add_a_business_id = this.$route.query.add_a_business_id;
        if (add_a_business_id) {
          await create_request_resolution_task(shop_id, add_a_business_id);
        }

        const company_information = !this.directory_shop
          ? `${this.company_details.company_name} \n` +
            `${this.company_details.address_1} ${
              this.company_details.address_2 || ""
            } ${this.company_details.address_3 || ""} \n` +
            `${this.company_details.town} ${this.company_details.postcode} \n` +
            `Registration number: ${this.company_details.company_number}`
          : "";

        await create_shop({
          email: this.email,
          name: this.business_name,
          shop_id: shop_id,
          payment_processor: this.payment_processor,
          user_name: this.user_name,
          brand_id: this.brand,
          directory_shop: this.directory_shop,
          company_information: company_information,
          company_details: {
            ...(!this.directory_shop
              ? { ...this.company_details }
              : { ...clean_company_details }),
          },
        });
        this.success = true;

        if (add_a_business_id) {
          this.$router.replace({
            name: "admin-brands-id-requests",
            params: { brand_id: this.brand },
          });
        } else {
          this.$router.replace({ query: null });
        }
        this.$refs.register_form.reset();
        setTimeout(() => {
          this.are_assets_ready = true;
          if (this.community_admin_brand_id)
            this.get_shops(this.community_admin_brand_id);
          else this.get_shops();
        }, 5000);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  background-color: white;
  border-radius: 10px;
}
.register-form {
  width: 50vw;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: unset;
  }
}
</style>
